import axios from "axios";

export const http = axios.create({
	timeout: 30 * 1000, // maximo 1 min
	headers: {
		// 'Access-Control-Allow-Origin': '*',
    "Content-type": "application/json"
  }
});

export default http;